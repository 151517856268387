jQuery(document).ready(function($){

    /*
    |--------------------------------------------------------------------------
    | Vendor init
    |--------------------------------------------------------------------------
    */
   
    var sliders = $('.with-slider');

    initSlider(sliders);

    function initSlider(sliders){
        sliders.each(function(){
            var numSlides = $(this).attr('data-slides');
            var numSlidesLRM = $(this).attr('data-slides-lrm');
            var numSlidesTablets = $(this).attr('data-slides-tablets');
            var numSlidesPhones = $(this).attr('data-slides-phones');
            var prev = $(this).find('.slider-control-prev');
            var next = $(this).find('.slider-control-next');

            new Swiper($(this).find('.swiper-container'), {
                slidesPerView: numSlides,
                spaceBetween: 30,
                nextButton: next,
                prevButton: prev,

                breakpoints:{
                    768 : {
                        slidesPerView: numSlidesPhones
                    },

                    1100: {
                        slidesPerView: numSlidesTablets
                    },

                    1200: {
                        slidesPerView: numSlidesLRM
                    }
                }
            });
        });
    }

    var bannerSlider = new Swiper('.banner-slider', {
        slidesPerView: 1,
        autoplay: 3000,
        effect: 'fade',
        speed: 1000,
        fade: {
            crossFade: true
        }
    });

    /*
    |--------------------------------------------------------------------------
    | Features open
    |--------------------------------------------------------------------------
    */
   
    $('.open-features').click(function() {
        $('.features-content').toggleClass('active');
        $(this).toggleClass('active');
    });
    

    /*
    |--------------------------------------------------------------------------
    | Main slider articles height adjustment
    |--------------------------------------------------------------------------
    */
   
    function mainSliderHeight(){
        var imageHeight = $('.main-slider .image .responsive-image.active').height() / 4;

        $('.main-slider__article-list-item').each(function(){
            var textHeight = $(this).find('h3').height();
            var padding = (imageHeight - textHeight) / 2;
            //console.log(imageHeight, textHeight, padding);
            $(this).css('height', imageHeight);
            $(this).css('padding-top', padding);
            $(this).css('padding-bottom', padding);
        });

        
    }

    function initMainSlider() {
        // Remove active classes
        $('.main-slider__article-list-item').removeClass('active');
        $('.main-slider .responsive-image').removeClass('active');

        // Add active classes
        $('.main-slider__article-list-item').first().addClass('active');
        $('.main-slider .responsive-image').first().addClass('active');
    }

    $('.main-slider').imagesLoaded(function () {
        if($(window).width() > 768){
            mainSliderHeight();
        }

        initMainSlider();
    });

    $(window).resize(function(){

        if($(window).width() > 768){
            mainSliderHeight();
        }
    });

    /*
    |--------------------------------------------------------------------------
    | Main slider image change on hover, active post
    |--------------------------------------------------------------------------
    */
   
    $('.main-slider__article-list-item a').hover(function(){
        var whatImage = $(this).attr('rel');

        // Remove active classes
        $('.main-slider__article-list-item').removeClass('active');
        $('.main-slider .responsive-image').removeClass('active');
        
        // Add active classes
        $(this).parent().parent().addClass('active');
        $('.responsive-image#' + whatImage).addClass('active');
    });

    /*
    |--------------------------------------------------------------------------
    | Search bar extend width on focus
    |--------------------------------------------------------------------------
    */
    $(window).click(function() {
        $("#suggesstion-box").hide();
        $('.search').removeClass('extend');
    });

    $('.search').click(function (e) {
        e.stopPropagation();
    });
    $('.search-form input').focus(function(){
        $(this).parent().parent().addClass('extend');
        $("#suggesstion-box").show();
    });
    /*
    |--------------------------------------------------------------------------
    | Sidebar drop down
    |--------------------------------------------------------------------------
    */
   
    $('.hasDropDown').each(function(){
        $(this).append('<span class="dd-button"><span class="icon-arrow-down"></span></span>');
        var dd = $(this).find('.hasDropDown');
        if(dd.length > 0){
            $(this).addClass('parentDropDown');
            dd.addClass('isChild');
        }
    });
   
    $('.dd-button').click(function(){
        if($(this).parent().hasClass('active')){
            $(this).parent().removeClass('active');
        } else {
            if($(this).parent().hasClass('isChild')){
                $('.sidebar li.active.isChild').removeClass('active');
                $(this).parent().addClass('active');
            } else {
                $('.sidebar li.active').removeClass('active');
                $(this).parent().addClass('active');
            }
        }
    });

    
    /*
    |--------------------------------------------------------------------------
    | Accordion
    |--------------------------------------------------------------------------
    */
   
    $('.accordion').each(function(){
        var accordionChild = $(this).find('.accordion');
        if(accordionChild.length > 0){
            $(this).addClass('isAccordionParent');
            accordionChild.addClass('isAccordionChild');
        }
    });
   
    $('body').on('click', '.accordion > h3', function(){

        if($(this).hasClass('no-click')) return;
        
        if($(this).parent().hasClass('active')){
            $(this).parent().removeClass('active');
            $(this).parent().children('.accordion-content').slideUp();
            $(this).find('.open-close').removeClass('active');
        } else {
            if($(this).parent().hasClass('isAccordionChild')){
                $('.accordion.isAccordionChild').removeClass('active');
                $('.accordion.isAccordionChild').find('.open-close').removeClass('active');
                $('.accordion.isAccordionChild .accordion-content').slideUp('active');
                $(this).parent().addClass('active');
                $(this).find('.open-close').addClass('active');
                $(this).parent().children('.accordion-content').slideDown();
            } else {
                $('.accordion').removeClass('active');
                $('.accordion').find('.open-close').removeClass('active');
                $('.accordion .accordion-content').slideUp('active');
                $(this).parent().addClass('active');
                $(this).find('.open-close').addClass('active');
                $(this).parent().children('.accordion-content').slideDown();
            }
        }


        $(this).trigger("toggleaccordion");
        // Initialise slider if there is one
        var sliderInAccordion = $(this).parent().find('.with-slider');
        initSlider(sliderInAccordion);
    });

    /*
    |--------------------------------------------------------------------------
    | Branches Menu
    |--------------------------------------------------------------------------
    */
   
    $('.branches-menu__trigger').click(function(){
        $(this).parent().find('ul:first').toggle();
        $(this).find('.branches-menu__change span').toggleClass('active');
    });

    /*
    |--------------------------------------------------------------------------
    | Modal
    |--------------------------------------------------------------------------
    */

    window.showModal = function showModal(whatModal, whatTab) {

        $('.modal-overlay').addClass('active');

        $('#'+whatModal).addClass('active');

        var height = $('body').height();
        $('#'+whatModal + ' .modal-content').css('max-height', height-150);

        $('body').addClass('no-scroll');

        $('#'+whatModal + ' .tab-control a#' + whatModal + '-tab-button').click();

        $('#'+whatTab + '-tab-button').trigger('click');
    };
    
    $('.open-modal').click(function(){
        var whatModal = $(this).attr('rel');
        var whatTab = $(this).data('tab');

        showModal(whatModal, whatTab);
    });

    $('.close-modal').click(function(){
        $('.modal').removeClass('active');
        $('.modal-overlay').removeClass('active');
        $('body').removeClass('no-scroll');
    });

    /*
    |--------------------------------------------------------------------------
    | Tabs
    |--------------------------------------------------------------------------
    */
    
    $('.tab-control a, .link-to-tab').click(function(){
        var whatTab = $(this).attr('rel');

        $(this).siblings().removeClass('active');
        
        $(this).addClass('active'); 
        
        var toActivate = $('#'+whatTab);

        toActivate.siblings('.tab').hide();

        toActivate.show();
    });

    $('.link-to-tab').click(function(){
        var whatTab = $(this).attr('rel');
        $('.tab-control a').removeClass('active');
        $('.tab-control a[rel='+whatTab+']').addClass('active'); 
        
        $('.tab').hide();
        
        $('#'+whatTab).show();
    });

    /*
    |--------------------------------------------------------------------------
    | Datepicker
    |--------------------------------------------------------------------------
    */
    $(".datepicker").flatpickr({
        minDate: new Date(),
        dateFormat: 'd.m.Y'
    });


    /*
    |--------------------------------------------------------------------------
    | Dropdowns
    |--------------------------------------------------------------------------
    */

    $('.language-trigger').click(function() {
        $(this).toggleClass('active');
        $('.language-dropdown').toggleClass('active');
    });

    $('.profile-trigger').click(function() {
        $(this).toggleClass('active');
        $('.profile-menu').toggleClass('active');
    });
    
    $(document).on('click', function(event) {
	  if (!$(event.target).closest('.language-trigger').length) {
	    // Hide the menus.
	    $('.language-dropdown, .language-trigger').removeClass('active');
	  }
	});

    $(document).on('click', function(event) {
	  if (!$(event.target).closest('.profile-trigger').length) {
	    // Hide the menus.
	    $('.profile-menu, .profile-trigger').removeClass('active');
	  }
	});


});