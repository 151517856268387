function getCookies() {
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        cookies[String(pair[0]).trim()] = unescape(pair[1]);
    }
    return cookies;
}


/**
 * Delete warning's
 * */
var deleteSwalDefaults = {
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: Lang.get('labels.confirm'),
    cancelButtonText: Lang.get('labels.cancel'),
    closeOnConfirm: true
};

function deleteWarning(self, e) {
    deleteSwalDefaults.title = Lang.get('labels.delete_question', {object: $(self).data('deleting')});
    swal(deleteSwalDefaults,
        function (isConfirm) {
            if (isConfirm) self.submit();
        });
}

function ajaxDeleteWarning(object, cb, text) {

    if (text)
        deleteSwalDefaults.text = text;

    deleteSwalDefaults.title = Lang.get('labels.delete_question', {object: object});
    swal(deleteSwalDefaults,
        function (isConfirm) {
            if (isConfirm) cb();
        });
}

function initTable(unSortable, initialOrder) {

    initialOrder = initialOrder || [0, "desc"];

    window.oTable = $('#table').DataTable({
        "aoColumnDefs": [
            {"aTargets": [0]},
            {'bSortable': false, 'aTargets': unSortable}
        ],
        "oLanguage": {
            "sLengthMenu": "Show _MENU_ Rows",
            "sSearch": "",
            "oPaginate": {
                "sPrevious": "<i class='fa fa-arrow-left'></i>",
                "sNext": "<i class='fa fa-arrow-right'></i>"
            }
        },
        "aaSorting": [[1, 'asc']],
        "aLengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"] // change per page values here
        ],
        // set the initial value
        "iDisplayLength": 10,
        "order": [initialOrder]
    });

    postInitJs('#table');
}

function initAjaxTable(ajax, unSortable, initialOrder) {

    initialOrder = initialOrder || [0, "desc"];

    window.oTable = $('#table').DataTable({
        "aoColumnDefs": [
            {"aTargets": [0]},
            {'bSortable': false, 'aTargets': unSortable}
        ],
        "oLanguage": {
            "sLengthMenu": "Show _MENU_ Rows",
            "sSearch": "",
            "oPaginate": {
                "sPrevious": "<i class='fa fa-arrow-left'></i>",
                "sNext": "<i class='fa fa-arrow-right'></i>"
            },
            sProcessing: 'Obrađujem...'
        },
        "aaSorting": [[1, 'asc']],
        "aLengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"] // change per page values here
        ],
        // set the initial value
        "iDisplayLength": 10,
        "order": [initialOrder],
        "processing": true,
        "serverSide": true,
        ajax: ajax,
        "drawCallback": function (settings) {
            $('.tooltips').tooltip();

            $('.delete-warn').on({
                click: function (e) {
                    e.preventDefault();
                    deleteWarning(this, e);
                },
                submit: function (e) {
                    e.preventDefault();
                    deleteWarning(this, e);
                }
            });
        }
    });

    postInitJs('#table_wrapper');
}

/**
 * Init jquery plugins on data-table html
 * */
function postInitJs(tableId) {
    $(tableId + ' .dataTables_filter input').addClass("form-control input-sm").attr("placeholder", "Search");
    // modify table search input
    $(tableId + ' .dataTables_length select').addClass("m-wrap small");
    // modify table per page dropdown
    $(tableId + ' .dataTables_length select').select2();
    // initialzie select2 dropdown
    $('#table_wrapper').addClass("table-responsive");

}

function initSelectAll() {
    $('input[type="checkbox"].selectall').on('ifChecked', function (event) {
        $(this).closest("table").find(".foocheck").iCheck('check');
    }).on('ifUnchecked', function (event) {
        $(this).closest("table").find(".foocheck").iCheck('uncheck');
    });
}

//refresh csrf token after each vue http request
Vue.http.interceptors.push(function (request, next) {

    // modify request
    var xsrf = getCookies()["XSRF-TOKEN"];
    if (xsrf) {
        request.headers.set('X-XSRF-TOKEN', getCookies()["XSRF-TOKEN"]);
    } else {
        request.headers.set('X-CSRF-TOKEN', token);
    }

    next(function (response) {
        // modify response
        var token = response.headers.get('_token');

        if (token) {
            //set new token to each hidden form input
            _.each($('input[name="_token"]'), function (value) {
                $(value).val(token);
            });

            //set global token variable to new token
            window.token = token;

            //set meta token value to new token
            $('meta[name="csrf-token"]').attr('content', token);
        }
    });

});

jQuery(document).ready(function () {
    // Main.init();
    // PagesGallery.init();
    $('.delete-warn').on({
        click: function (e) {
            e.preventDefault();
            deleteWarning(this, e);
        },
        submit: function (e) {
            e.preventDefault();
            deleteWarning(this, e);
        }
    });

    $('form').submit(function () {
        if ($(this).hasClass('ignore-loader')) return;
        $('#after-post-loader').show();
    });

    /*Bootstrap tabs, open correct tab via direct link*/
    // Javascript to enable link to tab
    // var url = document.location.toString();
    // if (url.match('#')) {
    //     $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    // }

    // Change hash for page-reload
    // $('.nav-tabs a').not('.no-url').on('shown.bs.tab', function (e) {
    //     window.location.hash = e.target.hash;
    // });

    $('.just-a-menu-link').unbind();


    // FRONTPAGE SLIDER
    /*var menu = ['Slide 1', 'Slide 2', 'Slide sakdfkasdk'];
    var swipeeer = new Swiper('.swiper-container_frontpage', {
        loop: true,
        paginationClickable: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="nav-number ' + className + '">' + (menu[index]) + '</span>';
            }
        },
        // navigation arrows
        navigation: {
            nextEl: '#frontendGal_next',
            prevEl: '#frontendGal_prev'
        }
    });*/


    // DOGAĐAJI PAGE REGION FILTER

    var countryGroupMenu = $('ul.countryGroup');
    var countryElement = countryGroupMenu.find('ul.mainSubMenu > li');
    if (countryElement.length > 0) {
        countryElement.on('click', function () {
            // $(countryElement).each(function (el, val) {
            //     $(val).removeClass('selected');
            // });
            var $this = $(this);
            countryElement.removeClass('selected');
            $this.addClass('selected');

            var subMenu = $this[0].children;

            if ($(subMenu).hasClass('show')) {
                $(subMenu).removeClass('show');
                $this.removeClass('selected');
            } else {
                $(subMenu).addClass('show');
            }
        });
        $('ul.subMenu > li').on('click', function (el) {
            var selectedCountry = $(this)[0].innerText;
            $(countryGroupMenu).find('li > span')[0].innerText = selectedCountry
        });
    }

    var natjecaji__acordion = $('.natjecaji__wrapp___content');
    if (natjecaji__acordion) {
        natjecaji__acordion.on('click', function () {
            $(this).toggleClass('selected');
        });
    }


    $('.toggle').click(function (e) {
        e.preventDefault();
        var $this = $(this);
        if (!$this.parent().parent().hasClass('show')) {
            $('.clicked').removeClass('clicked');
        }

        $this.addClass('clicked');

        if ($this.next().hasClass('show')) {
            $this.next().removeClass('show');
            $this.next().slideUp(350);
            $this.removeClass('clicked');

        } else {
            $this.parent().parent().find('li .inner').removeClass('show');
            $this.parent().parent().find('li .inner').slideUp(350);
            $this.next().addClass('show');
            $this.next().slideToggle(350);


        }
    });

    // Menu hover overlay activation

    $('.menu__item.has__children').hover(function () {
        var $this = $(this);
        $this.toggleClass('showOverflowModal');
    });

    // SEARCH FORM RESONSIVE EXPAND

    $('i.search--icon').on('click', function () {
        $('.search--input').toggleClass('showSearchForm');
    });


    $('body').on('click', function (e) {
        e.stopPropagation();
        $('.search--input').removeClass('showSearchForm');
    });

    $('.title-search--hamburger').on('click', function () {
        $('body').css('overflow', 'hidden');
    });

    $('.menu-fixed__wrapper__close').on('click', function () {
        $('body').css('overflow', '');
    });


    // MAIN HEADER
    var headerMain = $('#header');
    var aTop = headerMain.height();
    var headerTitle = $('#header-title');
    $(window).scroll(function () {
        if ($(this).scrollTop() > aTop) {
            $('body').css('padding-top', aTop);
            headerMain.addClass('active_menu');
            headerTitle.text('HGK.HR');
        } else {
            $('body').css('padding-top', 0);
            headerMain.removeClass('active_menu');
            headerTitle.text('HRVATSKA GOSPODARSKA KOMORA');
        }
    });


    var predstavnistvaTitlovi = $('#predstavnistvaSector .sectors__sector');
    if (predstavnistvaTitlovi) {
        var linkText;
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(
                Array.prototype.slice.call(arguments, 1)));
            return this;
        };
        predstavnistvaTitlovi.each(function (index, item) {

            linkText = $(item).find('.sectors__link')[0].innerText.split(' ').insert(3, '<br>').join(' ');


            $(item).find('.sectors__link')[0].innerHTML = linkText;
        });
    }
});

// ====================================================
// HEADER VUE
// ====================================================

var header = new Vue({
    el: '#header',
    data: {
        sidebarMenu: false,
        showDropdown: false,
        showSideDropdown: null,
        scrollPosition: null,
        showSearch: true,
        showTopMenu: true,
        showModal: false,
        showModalPredstavnistva: false,
        showModalSektori: false,
        showModalStrukovne: false,
        datastrukovne: false,
        closeAction: Function
    },
    ready: function () {
        this.toggleTopMenu();
    },
    methods: {
        toogleSidebarMenu: function (param) {
            this.sidebarMenu = param;

            if (param === false) {
                document.getElementsByTagName('body')[0].style.overflow = "auto";
            }
        },

        closeDialog: function () {
            this.removeQueryModal();
            this.showModal = false;
        },
        openModal: function () {
            this.showModal = true;
        },
        closeDialogPred: function () {
            this.removeQueryModal();
            this.showModalPredstavnistva = false;
        },
        openModalPred: function () {
            this.showModalPredstavnistva = true;
        },
        closeDialogSektori: function () {
            this.removeQueryModal();
            this.showModalSektori = false;
        },
        openModalSektori: function () {
            this.showModalSektori = true;
        },
        closeDialogStrukovne: function () {
            this.removeQueryModal();
            this.showModalStrukovne = false;
        },
        openModalStrukovne: function (data, isGrupacije) {
            this.datastrukovne = data;
            this.showModalStrukovne = isGrupacije ? 'Grupacije' : 'Skupine';
        },
        toogleDropdownMenu: function () {
            this.showDropdown = !this.showDropdown;
        },
        removeQueryModal: function () {
            url = new URL(window.location.href);
            value = url.searchParams.get('queryModal');
            if (value !== null) {
                modifiedValue = 'queryModal=' + value;
                modifiedUrl = url.search.replace(modifiedValue, '');
                window.history.replaceState({}, document.title, url.pathname + modifiedUrl);
            }

            //window.history.replaceState({}, document.title, "/" + "");
        },
        updateScroll: function () {
            this.scrollPosition = window.scrollY;
        },
        updateWidth: function () {
            if (document.documentElement.clientWidth < 1270) {
                this.showSearch = false;
            }
        },
        updateTopMenu: function () {
            if (document.documentElement.clientWidth < 576) {
                this.showTopMenu = false;
            }
        },
        toggleTopMenu: function () {
            if (document.documentElement.clientWidth < 576) {
                console.log('radi');
                this.showTopMenu = !this.showTopMenu;
            }
        },
        toggleSearchField: function () {
            if (document.documentElement.clientWidth < 1270) {
                this.showSearch = !this.showSearch;
            }
        }
    },
    computed: {}
});

var footer = new Vue({

    el: '#footer',

    data: {
        fee: '',
        contact: ''
    },
    methods: {
        // Real time update methods start
        updateFee: function (e) {
            this.fee = e.target.innerText;
        },
        updateContact: function (e) {
            this.contact = e.target.innerText;
        },
        // Real time update methods end
        // Validate and return data in component start
        returnFee: function () {
            return '<p>Članarine</p><p>IBAN ZA UPLATU<br>ČLANARINE HGK:<br>HR8510010051700052620</p><p>&nbsp;</p><p>MB: 3206190</p><p>OIB. 85167032587</p><p>ŽIRO RAČUN: 2340009-1100241295</p><p>IBAN: HR1423400091100241295</p><p>SWIFT: PBZGHR2X</p>'
        },

        returnContact: function () {
            return '<p>ROOSEVELTOV TRG 2,<br>10000 ZAGREB</p><p>BESPLATNI INFO-TELEFON:<br>0800 1852</p><p>TELEFON: +385 1 456-1555</p><p>ELEKTRONIČKA POŠTA:<br>HGK@HGK.HR</p>'
        },


    },


});

// DOGAĐAJI SLIDER

var swiperMorePostSlider = new Swiper('#morePostsSlider', {
    slidesPerView: 3,
    spaceBetween: 30,
    paginationClickable: true,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },
    breakpoints: {
        // when window width is <= 320px
        992: {
            slidesPerView: 2,
            spaceBetween: 10
        },
        481: {
            slidesPerView: 1,
            spaceBetween: 30
        }
    },
    navigation: {
        nextEl: '#js-prev1',
        prevEl: '#js-next1',
    }
});


// ANALIZE I PUBLIKACIJE STRANICA

var analizeSwiper = new Swiper('#analasisSlider', {
    slidesPerView: 3,
    spaceBetween: 30,
    breakpoints: {
        // when window width is <= 320px
        992: {
            slidesPerView: 2,
            spaceBetween: 10
        },
        481: {
            slidesPerView: 1,
            spaceBetween: 30
        }
    },

    navigation: {
        prevEl: '#prevBtn',
        nextEl: '#nextBtn',
    }
});


var projects_and_actions_slider = new Swiper('#projects__slider', {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
        prevEl: '#js-prev',
        nextEl: '#js-next',
    },
    breakpoints: {
        // when window width is <= 320px
        721: {
            slidesPerView: 2,
            spaceBetween: 10
        },
    }
});


$(function () {
    $('#submitSub').click(function (e) {
        subscribeLogic(e);
    });
});

(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o), m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
ga('create', 'UA-54448022-1', 'auto');
ga('send', 'pageview');

//

function test() {
    subscribeLogic();
}

function subscribeLogic(e) {
    if (e) {
        e.preventDefault();
    }
    
        Swal({
            title: 'Predbilježba na e-poštu',
            text: 'Predbilježite se na redovitu e-poštu s aktualnostima!',
            input: 'text',
            inputValue: '',
            showCancelButton: true,
            cancelButtonText: 'Odustani',
            inputPlaceholder: 'Vaša e-mail adresa',
            confirmButtonText: 'Nastavak',
            inputValidator: function (result) {
                return !result && 'Trebate prihvatiti uvjete!'
            }
        }).then(function (result) {
            console.log(result, 'result')

            if (result.value) {
                Swal({
                    title: 'Privola za slanje e-pošte s aktualnostima (e-letka)',
                    text: 'DA, želim primati redovitu e-poštu s aktualnostima (e-letak) i dajem dopuštenje da Hrvatska gospodarska komora koristi moje osobne podatke u svrhu slanja redovite e-pošte s aktualnostima.',
                    input: 'checkbox',
                    inputValue: 0,
                    showCancelButton: true,
                    cancelButtonText: 'Odustani',
                    inputPlaceholder: '',
                    confirmButtonText: 'Nastavak',
                    inputValidator: function (result) {
                        return !result && 'Trebate prihvatiti uvjete!'
                    }
                }).then(function (result2) {
                    if (result2.value) {
                        $.ajax({
                            url: '/subscriber',
                            method: "POST",
                            data: {
                                email: result.value,
                                _token: $('meta[name="token"]').attr('content'),
                                lang: lang.current,
                                _method: 'PUT'
                            },
                            success: function (data) {
                                if (data == '1') {
                                    Notie('success', lang.subscribeSuccess);
                                } else if (data == '2') {
                                    Notie('success', lang.againSuccess);
                                } else {
                                    Notie('error', data);
                                }
                            }
                        });
                    }
                });
            }

        });
}